import type { CompanyPartners } from '~/types';

export const SkPartnersList: CompanyPartners[] = [
    {
        name: 'NLL',
        label: 'National Lacrosse League',
        url: 'http://www.nll.com',
        logoSolid: 'NLL-Solid.svg',
        logoColor: 'NLL-Color.svg',
        width: 243,
        height: 96
    }
];
