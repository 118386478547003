<template>
    <div>
        <div
            v-for="partner in SkPartnersList"
            :key="partner.name"
            class="d-flex flex-shrink-1 flex-grow-0 flex-column justify-center align-center"
        >
            <a
                :href="partner.url"
                target="_blank"
                :aria-label="partner.label"
            >
                <v-img
                    width="240"
                    :aspect-ratio="partner.width / partner.height"
                    :src="getResourceCdnUrl(`${logoBasePath}/${partner.logoColor}`)"
                ></v-img>
            </a>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { SkPartnersList } from '~/models';
    import { useCdnResource } from '~/composables';

    const logoBasePath = '/static/common/images/svg/partners';
    const { getResourceCdnUrl } = useCdnResource();
</script>

<style lang="scss" scoped></style>
